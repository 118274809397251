import React from "react";
import { Link, graphql } from "gatsby";
import Bio from "../components/BlogAuthorBio";
import BlogEntryMetadata from "../components/BlogEntryMetadata";
import PostTags from "../components/PostTags";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageContent from "../components/PageContent";
import "../styles/BlogPost.scss";
import BlogAuthorBio from "../components/BlogAuthorBio";

class BlogPost extends React.Component {
  render() {
    const pageName = "blog-post";
    const post = this.props.data.post
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle} pageName={pageName}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <PageContent>
          <article className="blog-post">
            <h1 className="blog-post__title">
              {post.frontmatter.title}
            </h1>
            <BlogEntryMetadata
              author={this.props.data.author.frontmatter.name}
              date={post.frontmatter.date}
            />
            <div className="blog-post__content" dangerouslySetInnerHTML={{ __html: post.html }} />
            <PostTags tagList={post.frontmatter.tags} />
          </article>
          <div className="blog-post__author-bio">
            <BlogAuthorBio author={this.props.data.author} />
          </div>
          <div className="blog-post__pagination">
            {previous && (
              <Link to={`blog/${previous.fields.slug}`} rel="prev" className="blog-post__pagination-prev">
                ← Previous Post
              </Link>
            )}
            {next && (
              <Link to={`blog/${next.fields.slug}`} rel="next" className="blog-post__pagination-next">
                Next Post →
              </Link>
            )}
          </div>
        </PageContent>
      </Layout>
    )
  }
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $author: String!) {
    site {
      siteMetadata {
        title
      }
    }

    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }

    author: markdownRemark(fields: { slug: { eq: $author } }) {
      ...BlogAuthorBio
    }
  }
`
