import React from "react"
import "../styles/BlogEntryMetadata.scss";

class BlogEntryMetadata extends React.Component {
  render() {
    const { author, date } = this.props;

    return (
      <div className="blog-entry-metadata">
        <span className="blog-entry-metadata__author">
          by {author}
        </span>
        <span className="blog-entry-metadata__date">
          {date}
        </span>
      </div>
    )
  }
}

export default BlogEntryMetadata;
