import React from "react";
import { Link, graphql } from "gatsby";
import Image from "gatsby-image";
import "../styles/BlogAuthorBio.scss";

class BlogAuthorBio extends React.Component {
  render() {
    /* Consumers of Bio must make a MarkdownRemark query on the BlogAuthorBio fragment exported below.
    query {
      markdownRemark {
        ...BlogAuthorBio
      }
    }

    If refactoring in the future see if we can change this to a parameterized static query if the feature proposed
    here (https://github.com/gatsbyjs/gatsby/issues/10482) has been implemented.
    */

    const { author } = this.props;
    return (
      <aside className="blog-author-bio">
        <Image className="blog-author-bio__pic" fluid={author.frontmatter.image.childImageSharp.fluid} />
        <div>
          <header className="blog-author-bio__header">
            <Link className="blog-author-bio__name-link" to={`/blog/author/${author.fields.slug}`}>
              <h4 className="blog-author-bio__name">
                {author.frontmatter.name}
              </h4>
            </Link>
            <div className="blog-author-bio__metadata">
              {author.frontmatter.pronouns && (
                <span>
                  {author.frontmatter.pronouns}
                </span>
              )}
              {author.frontmatter.position && (
                <span>
                  {author.frontmatter.position}
                </span>
              )}
            </div>
          </header>
          <p className="blog-author-bio__description">{author.frontmatter.blurb}</p>
        </div>
      </aside>
    )
  }
}

export default BlogAuthorBio

export const query = graphql`
  fragment BlogAuthorBio on MarkdownRemark {
    fields {
      slug
    }
    frontmatter {
      name
      pronouns
      position
      blurb
      image {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;