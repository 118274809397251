import React from "react"
import { Link } from "gatsby";
import "../styles/PostTags.scss";
const _ = require(`lodash`)

class PostTags extends React.Component {
  render() {
    const { tagList } = this.props;
    const tagLinks = tagList.map(tag => (
      <li key={tag}>
        <Link to={`/blog/tags/${_.kebabCase(tag)}`}>
          {tag}
        </Link>
      </li>)
    );

    return (
      <div className="post-tags">
        {tagLinks && tagLinks.length &&
          (<ul>
            {tagLinks.reduce((prev, curr) => [prev, ", ", curr])}
          </ul>)
        }
      </div>
    );
  }
}

export default PostTags;